/* global trackbar, priceMin, priceMax, calCallbacks, Ya, onpage, noUiSlider, priceRangeSliders, orderedGoods, Sort, categoryUri, FACETS_SEPARATOR, ga, facetsSubgroups, fsGoogleMetrikaEvent */

/* For QuickView */
var isLiveSearch = true,
        priceRange, widthRange, heightRange;

var LiveSearch = new (function ($) {

  this.form = null;

  this.subgroups = [];

  this.inputDate = null;

  this.inputPriceMin = null;

  this.inputPriceMax = null;

  this.sliders = [];

  this.formData = '';

  this.results = {};

  var _this = this;

  var _uri, _url;

  var _sortHolder, _goodsHolder, _paginatorHolder;

  var _sort = 'pop';

  var _dontSaveHistory = false;

  var _dontSendHit = false;

  var _filterParams = [];

  var _filterGetParams = [];

  var fromPage = -1;

  this.init = function () {
    fromPage = from_page;
    this.form = $('#extsearch_form');
    this.subgroups = facetsSubgroups;
    this.inputDate = this.form.find('input[name=date]');
    this.inputPriceMin = this.form.find('input[name=price_low]');
    this.inputPriceMax = this.form.find('input[name=price_high]');
    this.inputWidthMin = this.form.find('input[name=width_low]');
    this.inputWidthMax = this.form.find('input[name=width_high]');
    this.inputHeightMin = this.form.find('input[name=height_low]');
    this.inputHeightMax = this.form.find('input[name=height_high]');

    _sortHolder = $('.sorted');
    _goodsHolder = $('.items');
    _paginatorHolder = $('.paginator-panel');

    $('.s_result_empty').html('<p>К сожалению, по Вашему запросу ничего не найдено.</p><p>Измените запрос или <button type="button" class="btn-link dotted" onclick="LiveSearch.reset();LiveSearch.googleMetrika(\'/yametrika/livesearch/reset-all/\', \'Кнопка &#34;сбросить все&#34;\', \'reset_all\');return false;">сбросьте параметры фильтров</button></p>');

    var parseQueryString = function (queryString) {
      var params = {}, queries, temp, i, l;

      queries = queryString.split("&");

      for (i = 0, l = queries.length; i < l; i++) {
        temp = queries[i].split('=');
        params[temp[0]] = temp[1];
      }

      return params;
    };

    if (location.search) {
      var queryString = location.search.substring(1);
      var queryParams = parseQueryString(queryString);
      if (queryParams['sort']) {
        _sort = queryParams['sort'];
      }
    }

    //filter_clones.init();

    _initSliders();

    /* Evets */
    $(window).bind('popstate', _onPopstate);
    this.form.find('input').change(function () {
      _this.update();

      if ("function" === typeof fsGoogleMetrikaEvent) {
        fsGoogleMetrikaEvent('filter_change');
      }
    });

    this.form.delegate("label a", "click", function () {
      var checkbox = $(this).parents("label").find("input[type=checkbox]");

      if (checkbox) {
        checkbox.click();
      }

      return false;
    });

    _setUrl();

    this.historySave(true);

    if (!/(&|)date=/i.test(location.search))
      selectDate.clear();

    //filter_clones.check();

    //popularObjects.hideAnother();
    if (0 < $("#f1r li").filter(function () {
      return $(this).css("display") === "none" ? true : false;
    }).find("input:checked").length) {
      popularObjects.showAnother();
    }

    return true;
  };

  this.historySave = function (replace) {
    if (_dontSaveHistory) {
      _dontSaveHistory = false;
      return;
    }
    var url = _uri,
            state = {
              params: _this.formData,
              page: num_page,
              sort: _sort,
              showFilters: $("#extsearch-mob-container").hasClass("show-layer-container")
            };

    if (replace) {
      window.history.replaceState(state, document.title, location.href);
    } else {
      window.history.pushState(state, document.title, url);
      $("link[rel=canonical]").attr("href", url);

      if (_dontSendHit) {
        _dontSendHit = false;
        return;
      }

      if ("function" === typeof yaMetrikaHit) {
        yaMetrikaHit(location.href, document.title);
      }

      if ("function" === typeof ga) {
        ga('send', 'pageview', location.pathname);
      }
    }
  };

  this.historyRestore = function () {
    var historyState = window.history.state;
    if (historyState) {

      if (historyState.showFilters) {
        $("#extsearch-mob-container").addClass("show-layer-container");
      } else if ($("#extsearch-mob-container").hasClass("show-layer-container")) {
        $("#extsearch-mob-container").removeClass("show-layer-container");
        return false;
      }

      _dontSaveHistory = true;
      _this.formData = historyState.params;
      num_page = historyState.page;
      _sort = historyState.sort;
      _inputsRestore(_this.formData);

      _uri = window.location.href;
      _url = window.location.protocol + '//' + window.location.host + _uri;

      _this.getResults(function () {
        //filter_clones.check();
      });

      if (_dontSendHit) {
        _dontSendHit = false;
        return true;
      }

      if ("function" === typeof yaMetrikaHit) {
        yaMetrikaHit(location.href, document.title);
      }

      if ("function" === typeof ga) {
        ga('send', 'pageview', location.pathname);
      }
      return true;
    }
    return false;
  };

  this.sort = function (dir) {
    _sort = dir;
    switch (dir) {
      case 'priceup':
      case 'pricedown':
        this.googleMetrika('/yametrika/livesearch/sort-price/', 'Сортировка результатов поиска по цене', 'sort_price');
        break;
      case 'pop':
        this.googleMetrika('/yametrika/livesearch/sort-pop/', 'Сортировка результатов поиска по популярности', 'sort_pop');
        break;
      case 'new':
        this.googleMetrika('/yametrika/livesearch/sort-new/', 'Сортировка результатов поиска по новизне', 'sort_new');
        break;
    }
    num_page = 0;
    fromPage = -1;

    _setUrl();
    this.getResults();
    return(false);
  };

  this.update = function () {
    //if ('#' + this.id === selectDate.calendar)
    //	selectDate.behavior();
    _subgroups();
    num_page = 0;
    fromPage = -1;
    //filter_clones.check();
    _setUrl();
    _this.getResults();
  };

  this.preloader = new (function () {

    var _isShow = false;

    this.show = function () {
      _isShow = true;
      setTimeout(function () {
        if (_isShow) {
          if ($('#ajaxpreloader').length === 0) {
            $('#preloader').show();
          }
        }
      }, 500);
    },
            this.hide = function () {
              _isShow = false;
              $("#goodsPage").removeClass("preloader");
              $('#preloader').hide();
            };
  });

  this.getResultsSuccess = function (callback) {
    if (this.results.cur_page < this.results.num_view_page) {
      num_page = this.results.num_view_page;
    }

    _hideInactive(this.results.facets);

    _renderDescription();

    if (this.results.count === 0) {
      _notFound();
      if (typeof callback === 'function') {
        callback();
      }
      return false;
    } else {
      $('.s_result_empty').hide();
      if (0 != $('.js-category-description').length) {
        $('.js-category-description').show();
      }
    }

    if (_dontSaveHistory) {
      _goodsHolder.html('');
    }

    if (this.results.cur_page === 1) {
      _goodsHolder.html('');
      //_sortHolder.hide();
      if (this.results.count > 1) {
        _renderSort();
      }
    }

    if (this.results.count > 0) {
      _renderGoods();
      _renderPaginator();
    }

    _renderPrices();

    $('#ajaxerror').hide().html('');
    $('#ajaxpreloader').hide();

    if (typeof callback === 'function')
      callback();

    this.historySave();
  };

  this.nextPage = function (callback) {
    _dontSendHit = true;
    if (num_page < 0) {
      num_page = 0;
    }

    if (-1 === fromPage) {
      fromPage = num_page;
    }

    num_page++;
    _setUrl();
    if (typeof window.scrollY !== 'undefined')
      var before = window.scrollY;
    this.getResults(function () {
      if (typeof callback === 'function')
        callback();
      if (typeof window.scrollY !== 'undefined') {
        if (before !== window.scrollY)
          window.scrollTo(0, before);
      }
    });

    this.googleMetrika("url", "", "btn_show_more");
  };

  this.getResults = function (callback) {
    var ajaxData = '';

    if (_dontSaveHistory) {
      ajaxData += 'all=1';
    }

    if (num_page > 1) {
      $("#goodsPage").addClass("preloader");
      ajaxData += '&page=' + num_page;
    }

    $.ajax({
      async: true,
      type: "POST",
      url: _uri,
      dataType: 'json',
      cache: false,
      data: ajaxData,
      context: this,
      beforeSend: function () {
        this.preloader.show();
      },
      error: function () {
        $('#ajaxerror').hide().html('Во время запроса произошла ошибка. Результаты поиска не обновлены.').fadeIn();
        this.preloader.hide();
        this.form.find('input').prop('disabled', '');
        num_page--;
      },
      success: function (result) {
        this.preloader.hide();
        this.results = result;
        this.getResultsSuccess(callback);
      }
    });
  };

  function _inputsRestore(params) {
    params = _deserialize(params);

    // Date
    if (typeof params['date'] !== 'undefined') {
      var date, d, m;

      fsCalendar.setDate(params['date']);
      document.getElementById("f_deldate").value = params['date'];
      date = fsCalendar.getDate();

      d = date.getUTCDate();
      m = date.getUTCMonth() + 1;
      $("#v-input-calendar").val((9 < d ? d : "0" + d) + "." + (9 < m ? m : "0" + m) + "." + date.getUTCFullYear());
    } else {
      selectDate.clear();
    }

    // Price, width, height and length
    _this.sliders.forEach(function (item) {
      if (!item.slider)
        return;
      var low = item.inputMin.attr('min'),
              high = item.inputMax.attr('max');
      if (typeof params[item.name + '-low'] !== 'undefined') {
        low = params[item.name + '-low'];
      }
      if (typeof params[item.name + '-high'] !== 'undefined') {
        high = params[item.name + '-high'];
      }
      item.inputMin.val(low);
      item.inputMax.val(high);
      item.slider.noUiSlider.set([low, high]);
    });
  }

  /* clear selected params from filter and call triggers event */
  this.localReset = function (_this, type) {
    switch (type) {
      case 'date':
        selectDate.clear();
        this.update();
        this.googleMetrika('/yametrika/livesearch/reset-date/', 'Сброс даты доставки', 'reset_date');
        break;
      case 'price':
        this.googleMetrika('/yametrika/livesearch/reset-price/', 'Сброс цены', 'reset_price');

        this.inputPriceMin.val("");
        this.inputPriceMax.val("");
        this.update();

        break;
      case 'width':
        this.inputWidthMin.val('');
        this.inputWidthMax.val('');
        widthRange.noUiSlider.set([this.inputWidthMin.attr('min'), this.inputWidthMax.attr('max')]);
        this.update();
        this.googleMetrika('/yametrika/livesearch/reset-width/', 'Сброс Ширина/диаметр', 'reset_width');
        break;
      case 'height':
        this.inputHeightMin.val('');
        this.inputHeightMax.val('');
        heightRange.noUiSlider.set([this.inputHeightMin.attr('min'), this.inputHeightMax.attr('max')]);
        this.update();
        this.googleMetrika('/yametrika/livesearch/reset-height/', 'Сброс Высота', 'reset_height');
        break;
      case 'sizes':
        this.inputWidthMin.val('');
        this.inputWidthMax.val('');
        this.inputHeightMin.val('');
        this.inputHeightMax.val('');
        widthRange.noUiSlider.set([this.inputWidthMin.attr('min'), this.inputWidthMax.attr('max')]);
        heightRange.noUiSlider.set([this.inputHeightMin.attr('min'), this.inputHeightMax.attr('max')]);
        this.update();
        this.googleMetrika('/yametrika/livesearch/reset-sizes/', 'Сброс Размеры', 'reset_sizes');
        break;
      case 'facete':
        var faceteId = parseInt($(_this).closest('li').data('facete-id'));
        if (faceteId) {
          var fTitle = $('#f' + faceteId + 'title').text();
          this.googleMetrika('/yametrika/livesearch/reset-facets/', 'Сброс в "' + fTitle + '"');
        }
        $(_this).closest('li').find('ul li input[type=checkbox]').prop('checked', false).eq(0).trigger('change');
        break;
    }
  };

  this.resetSort = function () {
    _sort = 'pop';
  };

  this.reset = function () {
    $("html,body").animate({
      scrollTop: 0
    }, {
      complete: function () {
        selectDate.clear();
        if (priceRange) {
          $(priceRange).attr('disabled', true);
          _this.inputPriceMin.val("");
          _this.inputPriceMax.val("");
        }
        if (widthRange) {
          _this.inputWidthMin.val('');
          _this.inputWidthMax.val('');
        }
        if (heightRange) {
          _this.inputHeightMin.val('');
          _this.inputHeightMax.val('');
        }

        _this.form.find('ul li input[type=checkbox]').prop('checked', false);
        _sort = 'pop';
        _this.update();
      }
    });
    return false;
  };

  this.googleMetrika = function (url, title, eventName) {
    if (typeof eventName !== 'undefined') {
      fsGoogleMetrikaEvent(eventName);
    }
  };

  function _hideInactive(facets) {
    _this.form.find(".js-f").each(function () {
      var li = $(this);
      var input = li.find("input");
      var idStr = li.attr("id").toString();
      var matches = idStr.match(/a(\d+)r/);
      var id = matches[1];
      var facet = facets[id] ? facets[id] : false;

      if (false === facet) {
        li.hide();
      } else {
        li.show();

        if (!facet.available) {
          li.addClass("inactive");
        } else {
          li.removeClass("inactive");
        }

        input.prop("checked", facet.enabled);
        input.prop("disabled", !facet.available);

        var l = $(this).find(".js-lnk");
        if (l) {
          if (facet.isCategory && !facet.categoryNoindex) {
            l.html('<a href="' + facet.uri + '" title="' + facet.categoryHeading + '">' + facet.name + '</a>');
          } else {
            l.html(facet.name);
          }
        }
      }
    });

    if (popularObjects.isHideState()) {
      popularObjects.hideAnother();
    } else {
      popularObjects.showAnother();
    }
  }

  function _notFound() {
    num_page = 0;
    fromPage = -1;
    //_sortHolder.hide();
    _goodsHolder.html('').hide();
    _paginatorHolder.hide();
    $(".js-paginator").hide();
    $('.s_result_empty').show();
    if (0 !== $('.js-category-description').length) {
      $('.js-category-description').hide();
    }
    _this.historySave();
    _this.googleMetrika('/yametrika/livesearch/empty-result/', 'Не найдено ни одного товара', 'empty_result');
  }

  function _subgroups() {

    if (1 < $("#f1l input:checked").length) {
      $(_this.subgroups).each(function (key, value) {
        $("#f" + value.gid + "r").slideUp();
      });
      return;
    }

    $(_this.subgroups).each(function (key, value) {
      if ($("#f1a" + value.fid).prop("checked") || $("#f12a" + value.fid).prop("checked")) {
        $("#f" + value.gid + "r").slideDown();
      } else {
        $("#f" + value.gid + "r input[type=checkbox]").prop("checked", false);
        $("#f" + value.gid + "r").slideUp();
      }
    });
  }

  function _setUrl() {
    var params = _getFormParams();
    _this.formData = params;

    _uri = categoryUri;

    if (_filterParams.length > 0) {
      _uri += _filterParams.join("/") + "/";
    }

    if (_filterGetParams.length > 0) {
      _uri += "?" + _filterGetParams.join("&");
    }

    _url = window.location.protocol + '//' + window.location.host + _uri;
  }

  function _deserialize(data) {
    var str = decodeURI(data), pairs = str.split('&'), obj = {}, p, idx, val;
    for (var i = 0, n = pairs.length; i < n; i++) {
      p = pairs[i].split('=');
      idx = p[0];
      if ((3 < idx.length) && (idx.indexOf("[]") == (idx.length - 2))) {
        var ind = idx.substring(0, idx.length - 2)
        if (obj[ind] === undefined)
          obj[ind] = [];
        obj[ind].push(p[1]);
      } else {
        obj[idx] = p[1];
      }
    }
    return obj;
  }

  function _numberFormat(price) {
    price = '' + price;
    var regexp = /(\d+)(\d{3})/;
    while (regexp.test(price)) {
      price = price.replace(regexp, '$1 $2');
    }
    return (price);
  }

  function _onPopstate() {
    _this.historyRestore();
  }

  function _getFormParams() {
    var params = [],
            date = _this.inputDate.val(),
            price_low = _this.inputPriceMin.val(),
            price_high = _this.inputPriceMax.val();

    _filterGetParams = [];

    if (date !== '') {
      var dateOffset = selectDate.getOffset();
      if (dateOffset === 0) {
        params.push('date=today');
        _filterGetParams.push('date=today');
      } else if (dateOffset === 1) {
        params.push('date=tomorrow');
        _filterGetParams.push('date=tomorrow');
      } else {
        params.push('date=' + date);
        _filterGetParams.push('date=' + date);
      }
    }
    if ((price_low !== priceMin.toString()) && (price_low !== '')) {
      params.push('price-low=' + price_low);
      _filterGetParams.push('price-low=' + price_low);
    }
    if ((price_high !== priceMax.toString()) && (price_high !== '')) {
      params.push('price-high=' + price_high);
      _filterGetParams.push('price-high=' + price_high);
    }

    // Sizes
    if ((_this.inputWidthMin.val() !== '') && (_this.inputWidthMin.val() !== _this.inputWidthMin.attr('min'))) {
      params.push('width-low=' + _this.inputWidthMin.val());
      _filterGetParams.push('width-low=' + _this.inputWidthMin.val());
    }
    if ((_this.inputWidthMax.val() !== '') && (_this.inputWidthMax.val() !== _this.inputWidthMax.attr('max'))) {
      params.push('width-high=' + _this.inputWidthMax.val());
      _filterGetParams.push('width-high=' + _this.inputWidthMax.val());
    }
    if ((_this.inputHeightMin.val() !== '') && (_this.inputHeightMin.val() !== _this.inputHeightMin.attr('min'))) {
      params.push('height-low=' + _this.inputHeightMin.val());
      _filterGetParams.push('height-low=' + _this.inputHeightMin.val());
    }
    if ((_this.inputHeightMax.val() !== '') && (_this.inputHeightMax.val() !== _this.inputHeightMax.attr('max'))) {
      params.push('height-high=' + _this.inputHeightMax.val());
      _filterGetParams.push('height-high=' + _this.inputHeightMax.val());
    }

    _filterParams = [];

    var ul = _this.form.find(".js-fg");

    ul.sort(function (a, b) {
      return +$(a).data("url-sort") - +$(b).data("url-sort");
    });
    ul.each(function () {
      var inputs = $(this).find('.js-f input[type=checkbox]:checked');

      inputs.sort(function (a, b) {
        return +$(a).data("url-sort") - +$(b).data("url-sort");
      });

      var groupUrlNames = [];
      inputs.each(function () {
        groupUrlNames.push(this.value);
      });
      if (0 < groupUrlNames.length)
        _filterParams.push(groupUrlNames.join(FACETS_SEPARATOR));
    });

    if ((_sort !== '') && (_sort !== 'pop')) {
      params.push('sort=' + _sort);
      _filterGetParams.push('sort=' + _sort);
    }

    if (num_page < 1)
      num_page = 1;
    if (num_page > 1) {
      params.push('page=' + num_page);
      _filterGetParams.push('page=' + num_page);
      if (-1 !== fromPage) {
        params.push('from-page=' + fromPage);
        _filterGetParams.push('from-page=' + fromPage);
      }
    }

    if (params.length > 0)
      return params.join('&');
    return '';
  }

  function _initSliders() {
    priceRange = document.getElementById('price-range');
    widthRange = document.getElementById('width-range');
    heightRange = document.getElementById('height-range');

    _this.sliders = [
      {
        inputMin: LiveSearch.inputPriceMin,
        inputMax: LiveSearch.inputPriceMax,
        name: 'price',
        caption: 'Цена',
        units: '&#8381;',
        slider: priceRange
      }, {
        inputMin: LiveSearch.inputWidthMin,
        inputMax: LiveSearch.inputWidthMax,
        name: 'width',
        caption: 'Ширина/диаметр',
        units: 'см',
        slider: widthRange
      }, {
        inputMin: LiveSearch.inputHeightMin,
        inputMax: LiveSearch.inputHeightMax,
        name: 'height',
        caption: 'Высота',
        units: 'см',
        slider: heightRange
      }
    ];

    _this.sliders.forEach(function (item) {
      if (!item.slider)
        return;
      if (item.name === 'price') {
        var start = [priceMin, priceMax];
        if (item.inputMin.val()) {
          start[0] = item.inputMin.val();
        }
        if (item.inputMax.val()) {
          start[1] = item.inputMax.val();
        }

        noUiSlider.create(priceRange, {
          start: start,
          behaviour: 'drag-tap',
          step: 1,
          connect: true,
          range: priceRangeSliders
        });
      } else {
        var start = [item.inputMin.attr('min'), item.inputMax.attr('max')];
        if (item.inputMin.val()) {
          start[0] = item.inputMin.val();
        }
        if (item.inputMax.val()) {
          start[1] = item.inputMax.val();
        }

        noUiSlider.create(item.slider, {
          start: start,
          behaviour: 'drag-tap',
          step: 1,
          connect: true,
          range: {
            'min': parseInt(item.inputMin.attr('min')),
            'max': parseInt(item.inputMax.attr('max'))
          }
        });
      }

      item.inputMin.on('change', function () {
        item.slider.noUiSlider.set([this.value, null]);
      });

      item.inputMax.on('change', function () {
        item.slider.noUiSlider.set([null, this.value]);
      });

      item.slider.noUiSlider.on('update', function (values, handle) {
        var value = values[handle];
        setTimeout(function () {
          value = Math.round(value);
          if (item.name === 'price') {
            if (value < priceMax && value > priceMin) {
              if (value % 100 !== 0)
                value = Math.round(value / 100) * 100;
            }
          }
          if (handle) {
            if (value.toString() === item.inputMax.attr('max'))
              value = '';
            item.inputMax.val(value);
          } else {
            if (value.toString() === item.inputMin.attr('min'))
              value = '';
            item.inputMin.val(value);
          }
        }, 0);
      });

      item.slider.noUiSlider.on('change', function () {
        var minVal = item.inputMin.val(),
                maxVal = item.inputMax.val(),
                rangeText = '';
        if (minVal && maxVal) {
          rangeText = minVal + ' - ' + maxVal;
        } else if (minVal) {
          rangeText = 'от ' + minVal;
        } else if (maxVal) {
          rangeText = 'до ' + maxVal;
        }
        if (rangeText !== '')
          _this.googleMetrika('/yametrika/livesearch/' + item.name + '-slider/', 'Слайдер "' + item.caption + '": ' + rangeText, item.name + '_slider');

        if ("function" === typeof fsGoogleMetrikaEvent) {
          fsGoogleMetrikaEvent('filter_change');
        }

        _this.update();
      });
    });
  }

  function _renderDescription() {
    if ("" === _this.formData) {
      $("#filter-show-result .js-count").hide();
    } else {
      $("#filter-show-result .js-count").html("(" + _this.results.total_count + ")").show();
    }

    var filters = $("#livesearch-filter-params .clone_filter_item").length;
    if (0 < filters) {
      $("#show-filters .js-count").html("(" + filters + ")").show();
    } else {
      $("#show-filters .js-count").hide();
    }

    if (_this.results.priceRange) {
      priceMin = parseInt(_this.results.priceRange.min);
      priceMax = parseInt(_this.results.priceRange.max);
      if (priceMin === priceMax) {
        $(priceRange).attr('disabled', true);
        _this.inputPriceMin.val("").attr("disabled", true);
        _this.inputPriceMax.val("").attr("disabled", true);
      } else {
        $(priceRange).attr('disabled', false);
        _this.inputPriceMin.attr("min", priceMin);
        _this.inputPriceMin.attr("max", priceMax);
        _this.inputPriceMin.attr("placeholder", priceMin);
        _this.inputPriceMax.attr("min", priceMin);
        _this.inputPriceMax.attr("max", priceMax);
        _this.inputPriceMax.attr("placeholder", priceMax);
        _this.inputPriceMin.attr("disabled", false);
        _this.inputPriceMax.attr("disabled", false);

        priceRange.noUiSlider.updateOptions({
          range: {
            'min': priceMin,
            'max': priceMax
          }
        });

        if ("" === _this.inputPriceMin.val() && "" === _this.inputPriceMax.val()) {
          priceRange.noUiSlider.set([priceMin, priceMax]);
        }
      }
    }

    document.title = _this.results.title;

    $("#js-breadcrumbs").html(_this.results.breadcrumbsHtml);
    $("h1").html(_this.results.heading);
    $("#livesearch-filter-params").html(_this.results.filterParamsHtml);
    $(".js-category-top-description").html(_this.results.topDescriptionHtml);
    $(".js-paginator").html(_this.results.paginatorHtml);
    $(".js-category-description").html(_this.results.description);
    $("meta[name=description]").attr("content", _this.results.metaDescription);
    $("meta[name=keywords]").attr("content", _this.results.metaKeywords);

    _subgroups();

    var adminPanel = document.querySelector("#jsddm ul");
    if (adminPanel) {
      var adminPanelAdd = adminPanel.querySelector(".js-admin-panel-categories-add");
      var adminPanelEdit = adminPanel.querySelector(".js-admin-panel-categories-edit");
      var adminPanelSort = adminPanel.querySelector(".js-admin-panel-categories-sort");
      if (adminPanelEdit) {
        if (0 < _this.results.cid) {
          if (adminPanelAdd) {
            adminPanelAdd.style.display = "none";
          }
          adminPanelEdit.style.display = "block";
          var adminPanelEditLink = adminPanelEdit.querySelector("a");
          if (adminPanelEditLink) {
            adminPanelEditLink.href = "/florist/categories/edit/" + _this.results.cid;
          }
          if (adminPanelSort) {
            adminPanelSort.style.display = "block";
            var adminPanelSortLink = adminPanelSort.querySelector("a");
            adminPanelSortLink.href = "/florist/categories/sort/" + _this.results.cid;
          }
        } else {
          if (adminPanelAdd) {
            adminPanelAdd.style.display = "block";
          }
          adminPanelEdit.style.display = "none";
          if (adminPanelSort) {
            adminPanelSort.style.display = "none";
          }
        }
      }
    }
  }

  function _renderSort() {
    Sort.update(_sort);
    //_sortHolder.show();
  }

  function _renderGoods() {
    if (_this.results.cur_page > 1) {
      //var goodsFromCount = _goodsHolder.find('li:not(.another)').length;
      //_goodsHolder.append('<li class="another goods_rule"><span>' + (goodsFromCount + 1) + '-' + (goodsFromCount + _this.results.count) + '</span></li>');
      _goodsHolder.append('<li class="another goods_rule"><span>Страница ' + _this.results.cur_page + " из " + _this.results.maxPages + '</span></li>');
    }

    _goodsHolder.append(_this.results.itemsHtml).show();
  }

  function _renderPaginator() {
    var count = _goodsHolder.find('li:not(.another)').length;
    $("#goods_shown_count").html(count);
    $("#goods_all_count").html(_this.results.total_count);

    if (_this.results.cur_page < _this.results.maxPages) {
      _paginatorHolder.find(".show_more").show();
    } else {
      _paginatorHolder.find(".show_more").hide();
    }

    _paginatorHolder.show();

    if (1 < _this.results.maxPages) {
      $(".js-paginator").show();
    } else {
      $(".js-paginator").hide();
    }
  }

  function _renderPrices() {
    var i = 0,
            pricesCount = _this.results.prices.length,
            pricesHolder = $('#calcprice');

    pricesHolder.html(''); // clear old prices

    if (pricesCount > 0) {
      for (i = 0; i < pricesCount; i++) {
        var
                range = _this.results.prices[i],
                name = [],
                min = priceMin, max = priceMax;

        if (range[0] > 0) {
          name.push('от');
          name.push(_numberFormat(range[0]));
          min = range[0];
        }
        if (range[1] > 0) {
          name.push('до');
          name.push(_numberFormat(range[1]));
          max = range[1];
        }
        var metrikaName = name.join(' ');
        name.push('&#8381;');

        pricesHolder.append('<li><a class="dottednew" onclick="LiveSearch.googleMetrika( \'/yametrika/livesearch/price-picker/\', \'Выбор диапазона цены из списка под слайдером (' + metrikaName + ')\',\'price_picker\'); setTrackbar(' + min + ', ' + max + '); return false;">' + name.join(' ') + '</a></li>');
      }
      $('#selectprices').show();
      $('#calcprice').show();
    } else {
      $('#calcprice').hide();
    }
  }
})(jQuery);

function extsearch_go_page(page, func) {
  LiveSearch.nextPage(func);
  return(false);
}

(function ($) {
  LiveSearch.init();
  Sort.init();
  paginator = new MobilePaginator({pagingElement: '#goodsPage', autoPages: 0, fullVersion: true});

  $(".filter ul ul").hide();
  $(".filter li p.toggle span").click(function () {
    $(this).closest("p").next().slideToggle(100);
    $(this).closest("p").toggleClass("opened");
  });

  $("#show-filters").click(function () {
    $("#catalog-mob-container").removeClass("show-layer-container");
    $("#extsearch-mob-container").toggleClass("show-layer-container");
  });
})(jQuery);

function setTrackbar(left, right) {
  LiveSearch.inputPriceMin.val(left);
  LiveSearch.inputPriceMax.val(right);
  priceRange.noUiSlider.set([left, right]);
  LiveSearch.update();
}
