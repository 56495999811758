/* Controller for datepicker selection */

var fsCalendar = new FsCalendar(document.getElementById("v-input-calendar"));

fsCalendar.onchange = function () {
  var date, d, m;

  date = fsCalendar.getDate();

  d = date.getUTCDate();
  m = date.getUTCMonth() + 1;
  document.getElementById("f_deldate").value = date.getUTCFullYear() + (9 < m ? m : "0" + m) + (9 < d ? d : "0" + d);
  $("#f_deldate").trigger("change");
};

$("#date-select-link .set-date").on("click", function () {
  var date, d, m, needleDate;
  needleDate = $(this).data("date");

  fsCalendar.setDate(needleDate);
  document.getElementById("f_deldate").value = date;
  $("#f_deldate").trigger("change");

  date = fsCalendar.getDate();

  d = date.getUTCDate();
  m = date.getUTCMonth() + 1;
  $("#v-input-calendar").val((9 < d ? d : "0" + d) + "." + (9 < m ? m : "0" + m) + "." + date.getUTCFullYear());

  return false;
});

var dateSelector = function () {
  var _this = this;

  this.calendar = '#f_deldate';

  this.behavior = function () {
    if (_this.getOffset() > 1) {
      LiveSearch.googleMetrika('/yametrika/livesearch/pick-dates/', 'Выбор даты доставки (' + _this.getFormatedDate() + ')', 'pick_dates');
    }
  };

  this.clear = function () {
//		$(_this.calendar).datepicker("setDate", ''); // set start date in interval
    document.getElementById("f_deldate").value = "";
    fsCalendar.reset();
  };

  this.getFormatedDate = function () {
    var prepareDate = fsCalendar.getDate();
    var options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long',
      timezone: 'UTC'
    };
    switch (_this.getOffset()) {
      case 0:
        return 'Сегодня &mdash; ' + prepareDate.toLocaleString('ru', options);
        break;
      case 1:
        return 'Завтра &mdash; ' + prepareDate.toLocaleString('ru', options);
        break;
      default:
        return prepareDate.toLocaleString('ru', options);
        break;
    }
  };

  this.getOffset = function () {
    /* Selected date */
    var selectedDate = fsCalendar.getDate();

    /* Current date */
    var currentDate = new Date();

    /* Processing */
    var diff = selectedDate.getTime() - currentDate.getTime();
    var offset = (((diff / 1000) / 3600) / 24); //count of days
    if (offset < 0) {
      offset = 0;
    } else {
      offset = Math.ceil(offset);
    }

    return offset; // return difference date
  };
};

var selectDate = new dateSelector();
