/**
 * Навигатор по постраничке для мобильной версии.
 * Инициализируется с максимальным количество страниц и подгружает
 * данные по одной
 *
 * @author Ivanov Dmitry
 * @date 20131122
 */
var MobilePaginator = function (opt) {
  var options = {
    'maxPages': 0,
    'autoPages': 0,
    'contentBlock': '.items',
    'pagingElement': '#mobilePage',
    'lastPagingElement': '',
    'preloader': '#mobilePreloader',
    'fullVersion': false
  },
          _this = this,
          currentPage = 1,
          countGoods = 1,
          totalGoods = null,
          inProgress = false,
          optionIndex = null,
          is_IndexPage = false,
          url = {
            'search': location.search,
            'pathname': location.pathname
          },
          eregiPaging = /(\/[a-z]*page)(\d*)(\.html)$/i,
          eregIndexPaging = /(\/[a-z]*page)=(\d*)$/i;

  if (typeof opt == 'object') {
    for (optionIndex in opt) {
      if (typeof options[optionIndex] != undefined) {
        options[optionIndex] = opt[optionIndex];
      }
    }
  }

  if (false === /.*\/$/.test(location.pathname)) {
    url.pathname += "/";
  }

  /**
   * Публичный интерфейс
   */
  /**
   * Устанавливает количество максимальных страниц
   *
   * @author Ivanov Dmitry
   * @date 20131125
   * @return null Не возвращает значений
   */
  this.setMaxPages = function (max) {
    if (!isNaN(parseInt(max))) {
      options.maxPages = parseInt(max);
    }
  }

  /**
   * Устанавливает начальную страницу
   * при условии что она отличается от дефолтной или ее еще не ставили
   * @author K.T.
   * @date 04.08.2015
   * @return null Не возвращает значений
   */
  this.setCurrentPage = function (currPage) {
    if (!isNaN(parseInt(currPage)) && currentPage == 1) {
      currentPage = parseInt(currPage);
      /*Раз мы ставим текущую страницу и она не последняя тогда нужно показать и кнопку "еще"*/
      if (parseInt(currPage) != options.maxPages) {
        $(options.pagingElement).show();
      }
    }
  }

  this.setCurrentPageForce = function (currPage) {
    currentPage = 1;
    this.setCurrentPage(currPage);
    $('#goods_shown_count').html(this.getCountGoods());
    $('#goods_more_count').html(this.getCountGoods());
    if (parseInt(currPage) != options.maxPages) {
      $(options.pagingElement).show();
    }
  }

  /**
   * Получает текущую страницу
   * @author K.T.
   * @date 20.08.2015
   * @return int
   */
  this.getCurrentPage = function () {
    return currentPage;
  }

  /**
   * Устанавливает количество элементов на "странице".
   */
  this.setCountGoods = function (cg)
  {
    countGoods = parseInt(cg);
  }

  /**
   * Устанавливает количество элементов на "странице".
   */
  this.getCountGoods = function ()
  {
    return countGoods;
  }

  /**
   * Устанавливает общее количество элементов.
   */
  this.setTotalGoods = function (tg)
  {
    totalGoods = parseInt(tg);
  }

  /**
   * Устанавливает общее количество элементов.
   */
  this.getTotalGoods = function ()
  {
    return totalGoods;
  }

  /**
   * Устанавливает количество автоматически подгружаемых страниц при прокрутке
   * после чего выдает кнопку "еще" для подгрузок по клику
   * @author K.T.
   * @date 12.08.2015
   * @return null Не возвращает значений
   */
  this.setAutoPages = function (autoPageCount) {
    if (!isNaN(parseInt(autoPageCount))) {
      options.autoPages = parseInt(autoPageCount);
    }
  }

  /**
   * Получает чество автоматически подгружаемых страниц при прокрутке
   * @author K.T.
   * @date 20.08.2015
   * @return int
   */
  this.getAutoPages = function () {
    return options.autoPages;
  }

  /**
   * Устанавливает id кнопки после последнего скролла (куда идти дальше)
   *
   * @author K.T.
   * @date 13.08.2015
   * @return null Не возвращает значений
   */
  this.setLastPagingElement = function (elem) {
    options.lastPagingElement = elem;
  }

  /**
   * Переводит работу модуля к пагинации на индексной странице
   *
   * @author K.T.
   * @date 11.08.2015
   * @return null Не возвращает значений
   */
  this.isIndexPage = function () {
    is_IndexPage = true;
  }

  /**
   * возвращает статус работы запроса AJAX
   * @author K.T.
   * @date 07.08.2015
   * @return bool
   */
  this.GetProgress = function () {
    return inProgress;
  }

  this.AutoPageAvailable = function () {
    if (currentPage > options.autoPages) {
      return false;
    } else {
      return true;
    }
  }

  /**
   * Осуществляет запрос получения товаров и переход на следующую страницу
   *
   * @author Ivanov Dmitry
   * @date 20131125
   * @return null Не возвращает значений
   */
  this.next = function (callback) {
    if ("function" === typeof extsearch_go_page) {
      extsearch_go_page();
      return;
    }
    if (currentPage >= options.maxPages) {
      return;
    }
    loadPage(callback);
  };

  /***************************************************
   * 													*
   * скрытые методы									**
   * 													*
   ***************************************************
   */

  /**
   * Скрывает дивак, отвечающий за постраничку
   *
   * @return null Не возвращает значений
   * @date 20131125
   * @author Ivanov Dmitry
   */
  this.hidePagingElement = function () {
    $(options.pagingElement).hide();
  }

  /**
   * Загружает страничку с товарами
   *
   * @return null Не возвращает значений
   * @date 20131125
   * @author Ivanov Dmitry
   */
  function loadPage(callback) {
    var scrollYBefore;
    if (is_IndexPage) {
      var requestURL = generateIndexRequestURL();
    } else {
      var requestURL = generateRequestURL();
    }
    $.ajax({
      url: requestURL,
      beforeSend: function (xhr) {
        $(options.preloader).show();
        inProgress = true;
        if (typeof window.scrollY !== 'undefined')
          scrollYBefore = window.scrollY;
      },
      success: function (data) {
        var _startGoodsPoint = currentPage * countGoods - countGoods + 1;
        var _currentGoods = currentPage * countGoods;
        if (totalGoods && _currentGoods > totalGoods)
          _currentGoods = totalGoods;
        if (countGoods != 1)
          $(options.contentBlock).append('<li class="another goods_rule"><span>' + _startGoodsPoint + '-' + _currentGoods + '</span></li>');
        $('#goods_shown_count').html(_currentGoods);
        if (totalGoods - _currentGoods < countGoods) {
          $('#goods_more_count').html(totalGoods - _currentGoods);
        }
        $(options.preloader).hide();
        $(options.contentBlock).append(data);
        if (typeof initLazyLoad == 'function') {
          initLazyLoad();
        } else {
          $(".lazy img").show().lazyload();
        }
        if (currentPage >= options.maxPages && !is_IndexPage) {//Надо бы скрыть кнопочку ЕЩЕ
          $(options.pagingElement).hide();
        } else if (is_IndexPage && currentPage == options.maxPages && options.lastPagingElement) {
          //для индексной страницы отдельное правило, там кнопка еще ведет в раздел. см goodslist.tpl
          $(options.pagingElement).hide(); // скрываем обычную кнопку
          $(options.lastPagingElement).show(); // показываем кнопку после последнего скролла
        } else if (_this.AutoPageAvailable()) {
          $(options.pagingElement).hide();
        } else {
          $(options.pagingElement).show();
        }
        inProgress = false;

        //$( '.lazy img').trigger('scroll');
        if ('function' == typeof callback) {
          callback();
        }
        if (typeof window.scrollY !== 'undefined') {
          if (scrollYBefore !== window.scrollY)
            window.scrollTo(0, scrollYBefore);
        }

        function historySave() {
          var state = {
            goodsHolderMobHtml: $('.items').html(),
            currentPage: currentPage,
            maxPages: options.maxPages,
            goods_shown_count: _currentGoods
          };

          url = window.location.pathname;

          window.history.replaceState(state, document.title, url);
          //window.history.pushState(state, document.title, url);
        }

        if (is_IndexPage) {
          historySave();
        }
      },
      error: function () {
        $(options.preloader).hide();
        console.log('В процессе загрузки данных произошла ошибка!');
        currentPage--;
        inProgress = false
      }
    });
  }

  /**
   * Генерирует URL для AJAX запроса получения списка товаров
   *
   * @return string Сгенерированный URL для AJAX запроса
   * @author Ivanov Dmitry
   * @date 20131125
   */
  function generateRequestURL() {
    var urlGenerated = '',
            urlSearch = '',
            urlPage = '';
    currentPage++;
    urlSearch = url.search.toString().replace(/(&|\?){1}ajax=1/i, '');

    if (urlSearch == '') {
      urlSearch = '?ajax=1';
    } else {
      urlSearch = urlSearch + '&ajax=1';
    }
    if (existPagingInURL()) {//Постраничка существует, тогда заменяем просто
      urlPage = url.pathname.replace(eregiPaging, '$1' + currentPage + '$3');
    } else {
      urlPage = url.pathname + 'page' + currentPage + '.html';
    }
    urlGenerated = urlPage + urlSearch;
    if ((typeof Sort !== 'undefined') && (Sort.getParams !== ''))
      urlGenerated += '&' + Sort.getParams;
    return urlGenerated;
  }

  /**
   * Генерирует URL для AJAX запроса получения списка товаров
   * ДЛЯ ГЛАВНОЙ СТРАНИЦЫ
   * @return string Сгенерированный URL для AJAX запроса
   * @author K.T.
   * @date 11.08.2015
   */
  function generateIndexRequestURL() {
    var urlGenerated = '',
            urlSearch = '/',
            urlPage = '';
    currentPage++;
    urlSearch = urlSearch + '?page=' + currentPage + '&ajax=1';
    if (typeof holidaySection != 'undefined') {
      if (holidaySection == 'valentine' || holidaySection == '8marta') {
        urlSearch = '/' + holidaySection + '/';
        urlSearch = urlSearch + 'page' + currentPage + '.html?ajax=1&fromIndex=1';
      }
    }
    return urlSearch;
  }

  /**
   * Проверяет присутствует ли в URL постраничка
   *
   * @return bool True - да, false- нет
   * @author Ivanov Dmitry
   * @date 20131126
   */
  function existPagingInURL() {
    return (eregiPaging.test(url.pathname));
  }

},
        paginator = null;
