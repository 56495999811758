/* Controller clones from filter attributes */

var cloneFilters = function () {
  var prefix = 'clone_';

  /*init the behavior clones*/
  this.init = function () {
  };

  /*Delete item from clone list*/
  this.deleteClone = function (o) {
    var id = $(o).data('id');
    if ($('#' + id).attr("type") == "radio") {
      var inputName = $('#' + id).attr("name");
      $('#' + id).prop('checked', false).trigger('change').closest('li').find('img.check').hide();
      $("input[name=" + inputName + "][value=0]").val('0').prop('checked', true).trigger("change");
    } else {
      $('#' + id).prop('checked', false).trigger('change').closest('li').find('img.check').hide(); //clear checked item inside filter elements
    }
    $(o).closest('#' + prefix + id).remove(); // delete container for clone item
    toggleContainer();
  };

  this.clear = function (local) {

  };

  /*checking preselect param from filter*/
  this.check = function () {

  };

  /* show/hide clone container by inner content */
  function toggleContainer() {
    if ($('#livesearch-filter-params .clone_filter_item').length > 0) {
      $('#livesearch-params-container .livesearch-filter-reset').show();
    } else {
      $('#livesearch-params-container .livesearch-filter-reset').hide();
    }
  }
};

var filter_clones = new cloneFilters();
