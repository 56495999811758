var Popular = function () {
  var popularBtn = 'showPopular',
          popularBtnID = '#' + popularBtn,
          popularButton = '#' + popularBtn + ' button',
          hrClass = 'hr',
          facetID = "#f1l",
          params = {
            'textShow': 'Показать все',
            'textHide': 'Показать только популярные'
          },
          _this = this;

  //do default settings for the button
  function doDefaultSettings() {
    btnSetShowCaption();
  }

  function btnSetHideCaption() {
    $(popularButton).html(params.textHide);
    $(popularBtnID).data("hide", false);
    $(popularBtnID).show();
  }

  function btnSetShowCaption() {
    $(popularButton).html(params.textShow);
    $(popularBtnID).data("hide", true);
    $(popularBtnID).show();
  }

  doDefaultSettings();

  this.hideAnother = function () {
    var foundPopular = false;
    $('.hr').hide();
    $(facetID + " li").each(function () {
      if ($(this).find("span[data-popular=1]").length) {
        foundPopular = true;
        $(this).show();
      } else if ($(this).find("span[data-popular=0]")) {
        $(this).hide();
      }
    });
    if (foundPopular) {
      btnSetShowCaption();
    } else {
      $(popularBtnID).hide();
      $(facetID + " li").each(function () {
        if (($(this).find("input:disabled").length > 0) || ($(this).attr("id") == popularBtn) || ($(this).hasClass(hrClass))) {
          return true;//Analogue for native continue;
        }
        $(this).show();
      });
    }
  };

  this.showAnother = function () {
    var foundFacet = false;
    $(facetID + " li").each(function () {
      foundFacet = true;
      $(this).show();
    });
    if (foundFacet) {
      $('.hr').show();
    }
    btnSetHideCaption();
  };

  this.isHideState = function () {
    return ($(popularBtnID).data("hide") == true);
  };

  this.toggle = function () {
    var currentVal = $(popularBtnID).data("hide");
    if (currentVal) {
      LiveSearch.googleMetrika('/yametrika/livesearch/baseflowers-expand/', 'Клик показать все цветы', 'baseflowers_expand');
      _this.showAnother();
    } else {
      LiveSearch.googleMetrika('/yametrika/livesearch/baseflowers-collapse/', 'Клик показать только популярные цветы', 'baseflowers_collapse');
      _this.hideAnother();
    }
  };

  return this;
};

var popularObjects = new Popular;
